"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container",
    staticStyle: {
      padding: "25px"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      md: 24
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("业态配置")])]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-top": "6px"
    }
  }, [_vm.checkPermission(["shop_type_create"]) ? _c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-plus",
      size: "small"
    },
    on: {
      click: _vm.handleAddConfig
    }
  }, [_vm._v("新增")]) : _vm._e()], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }, {
      name: "el-height-adaptive-table",
      rawName: "v-el-height-adaptive-table",
      value: {
        bottomOffset: 50
      },
      expression: "{ bottomOffset: 50 }"
    }],
    staticStyle: {
      width: "100%",
      "margin-top": "6px"
    },
    attrs: {
      data: _vm.configList,
      "highlight-current-row": "",
      "row-key": "id",
      stripe: "",
      border: "",
      "header-cell-style": {
        fontWeight: "700",
        backgroundColor: "#eaedf4"
      }
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      width: "50",
      label: "序号"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "标题"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(_vm._s(scope.row.title))];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "header-center",
      label: "状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(_vm._s(scope.row.status == 1 ? "启用" : "关闭"))];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "header-center",
      label: "合同模版id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(_vm._s(scope.row.templateId))];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "操作"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            disabled: !_vm.checkPermission(["shop_type_update"]),
            type: "primary",
            size: "small",
            icon: "el-icon-edit"
          },
          on: {
            click: function click($event) {
              return _vm.handleEdit(scope);
            }
          }
        }, [_vm._v("编辑")])];
      }
    }])
  })], 1)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      title: _vm.dialogType === "edit" ? "编辑配置" : "新增配置"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "Form",
    attrs: {
      model: _vm.config,
      "label-width": "80px",
      "label-position": "right"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "标题",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "title"
    },
    model: {
      value: _vm.config.title,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "title", $$v);
      },
      expression: "config.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "合同模版id",
      prop: "templateId"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "合同模版id"
    },
    model: {
      value: _vm.config.templateId,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "templateId", $$v);
      },
      expression: "config.templateId"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "状态",
      prop: "order_mode",
      "label-width": "150px"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.config.status,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "status", $$v);
      },
      expression: "config.status"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("开启")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 0
    }
  }, [_vm._v("关闭")])], 1)], 1)], 1), _vm._v(" "), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取消")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.confirm("Form");
      }
    }
  }, [_vm._v("确认")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;