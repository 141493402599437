"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    staticClass: "fullscreen-loading"
  }, [_vm._m(0)]), _vm._v(" "), _c("el-row", {
    staticStyle: {
      "padding-top": "20px",
      "padding-left": "20px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-plus",
      size: "small"
    },
    on: {
      click: _vm.setEwm
    }
  }, [_vm._v("报表上传二维码")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "default",
      icon: "el-icon-refresh-right",
      size: "small"
    },
    on: {
      click: _vm.reload
    }
  }, [_vm._v("刷新")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-refresh-right",
      size: "small"
    },
    on: {
      click: _vm.confirmMultiple
    }
  }, [_vm._v("生成聚合报表")])], 1), _vm._v(" "), _c("el-row", {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    staticStyle: {
      padding: "0 20px"
    },
    attrs: {
      md: 24
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    attrs: {
      data: _vm.list.results,
      border: "",
      stripe: "",
      "header-cell-style": {
        fontWeight: "700",
        backgroundColor: "#eaedf4"
      },
      "highlight-selection-row": ""
    },
    on: {
      "selection-change": _vm.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "60"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "username",
      label: "报告用户名称"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "mobile",
      label: "报告用户手机号"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "card_code",
      label: "报告用户证件号"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "type",
      label: "报告类型"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.type == 1 ? _c("el-tag", {
          staticStyle: {
            "margin-left": "5px"
          }
        }, [_vm._v("支付宝")]) : _c("el-tag", {
          staticStyle: {
            "margin-left": "5px"
          },
          attrs: {
            type: "success"
          }
        }, [_vm._v("微信")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "alipayNo",
      label: "报告单号"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "create_time",
      label: "请求时间"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "time_des",
      label: "报告时间范围"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      fixed: "right",
      label: "操作",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.is_get == 1 ? _c("el-button", {
          attrs: {
            type: "primary",
            round: "",
            size: "small"
          },
          on: {
            click: function click($event) {
              return _vm.handleClick(scope.row.id);
            }
          }
        }, [_vm._v("读取报告")]) : _vm._e(), _vm._v(" "), scope.row.is_get == 0 ? _c("el-button", {
          attrs: {
            type: "danger",
            round: "",
            size: "small"
          },
          on: {
            click: function click($event) {
              return _vm.handleCreate(scope.row.id);
            }
          }
        }, [_vm._v("生成报告")]) : _vm._e(), _vm._v(" "), scope.row.is_get == 2 ? _c("el-button", {
          attrs: {
            type: "warning",
            round: "",
            size: "small"
          },
          on: {
            click: function click($event) {
              return _vm.handleCreate2(scope.row.id);
            }
          }
        }, [_vm._v("生成报告")]) : _vm._e()];
      }
    }])
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.list.count > 0,
      expression: "list.count > 0"
    }],
    staticStyle: {
      "margin-top": "10px",
      "text-align": "center"
    },
    attrs: {
      total: _vm.list.count,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.page_size
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.listQuery, "page_size", $event);
      },
      pagination: _vm.getList
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "custom-loading"
  }, [_c("img", {
    staticClass: "loading-image",
    staticStyle: {
      width: "600px"
    },
    attrs: {
      src: require("../../assets/2.gif"),
      alt: ""
    }
  })]);
}];
render._withStripped = true;