"use strict";

var _interopRequireDefault = require("/www/wwwroot/node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _request = _interopRequireDefault(require("@/utils/request"));
var _request2 = _interopRequireDefault(require("@/utils/request1"));
var _addTagForm = _interopRequireDefault(require("./addTagForm.vue"));
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _auth = require("@/utils/auth");
var _default = exports.default = {
  components: {
    addTagForm: _addTagForm.default,
    Pagination: _index.default
  },
  data: function data() {
    return {
      dialogFormVisible: false,
      //是否显示新增form
      dialogFormVisible2: false,
      listLoading: true,
      pk: 0,
      tag_list: [],
      old_tag_list: [],
      shop_list: [],
      listQuery: {
        page: 1,
        page_size: 10
      },
      tagkey: '',
      tag_id: 0,
      pic_header: 'Bearer ' + (0, _auth.getToken)(),
      name: '',
      is_tag_list: true
    };
  },
  created: function created() {
    this.getTagList();
  },
  methods: {
    // 顶部添加按钮--显示新增form
    handleAdd: function handleAdd() {
      this.dialogFormVisible = true;
    },
    reload: function reload() {
      // 刷新页面
      // window.location.reload()
      this.getTagList();
    },
    getTagList: function getTagList() {
      var _this = this;
      (0, _request2.default)({
        url: "/get_flag_list",
        method: 'get'
      }).then(function (res) {
        // console.log(res)
        if (res.code == 1) {
          _this.tag_list = res.data;
          _this.is_tag_list = false;
        } else {
          _this.$message.error(res.msg);
          _this.is_tag_list = false;
        }
      });
    },
    showSelect: function showSelect(e) {
      this.dialogFormVisible2 = true;
      this.tag_id = e;
    },
    // 添加标签
    addTag: function addTag(val) {
      var _this2 = this;
      // 1.加载中
      this.listLoading = true;
      // 2.发送请求，添加
      (0, _request2.default)({
        url: "/add_flag",
        method: 'post',
        params: {},
        // url参数
        data: {
          name: val.name
        } // 请求体参数
      }).then(function (response) {
        if (response.code == 1) {
          _this2.$message.success('添加成功');
          _this2.listLoading = false;
          _this2.dialogFormVisible = false;
          _this2.getTagList();
        } else {
          _this2.$message.error(res.msg);
        }
      }).catch(function (err) {
        console.log(err, '新增失败');
      });
    },
    // 删除关键词
    handleClose: function handleClose(id) {
      var _this3 = this;
      // 用更新报表的接口
      (0, _request2.default)({
        url: "/del_flag_title",
        method: 'post',
        data: {
          id: id // 关键词id
        }
      }).then(function (res) {
        if (res.code == 1) {
          _this3.$message.success('删除成功');
          // this.reload()
          _this3.getTagList();
        } else {
          _this3.$message.error(res.msg);
        }
      });
      // console.log(id)
      // console.log(tag_id)
    },
    // 添加关键词
    addShopTag: function addShopTag() {
      var _this4 = this;
      // console.log(this.tagkey,this.tag_id)

      (0, _request2.default)({
        url: "/add_flag_title",
        method: 'post',
        data: {
          id: this.tag_id,
          title: this.tagkey
        }
      }).then(function (res) {
        if (res.code == 1) {
          _this4.$message({
            message: "\u6DFB\u52A0\u6210\u529F",
            type: 'success'
          });
          _this4.dialogFormVisible2 = false;
          // this.reload()
          _this4.getTagList();
        } else {
          _this4.$message({
            message: res.msg,
            type: 'error'
          });
          _this4.dialogFormVisible2 = false;
        }
      });
    },
    // 取消新增
    cancel: function cancel() {
      this.dialogFormVisible = false;
      // console.log(this.$refs['addTag'].ruleForm.name)
      this.$refs['addTag'].ruleForm.name = '';
    },
    clearTagKey: function clearTagKey() {
      this.dialogFormVisible2 = false;
      this.tagkey = '';
    }
  }
};