"use strict";

var _interopRequireDefault = require("/www/wwwroot/node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _RestTable = _interopRequireDefault(require("@/components/RestTable.vue"));
var _user = require("@/api/user");
var _default = exports.default = {
  data: function data() {
    return {
      typeList: {}
    };
  },
  // eslint-disable-next-line vue/no-unused-components
  components: {
    RestTable: _RestTable.default
  },
  created: function created() {
    this.getTypeList();
  },
  methods: {
    getTypeList: function getTypeList() {
      var _this = this;
      (0, _user.getShopTypeList)().then(function (response) {
        console.log(response);
        if (response.data) {
          response.data.map(function (item) {
            _this.typeList[item.pk] = item.title;
          });
          console.log(_this.typeList);
          // this.typeList[''] = response.data
        }
      });
    },
    handleFilter: function handleFilter() {
      this.$refs.target.handleFilter();
    },
    handleClick: function handleClick(row) {
      this.$router.push({
        name: 'preRegisterDetail',
        query: {
          id: row.id
        }
      });
    }
  }
};