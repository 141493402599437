"use strict";

var _interopRequireDefault = require("/www/wwwroot/node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _defineProperty2 = _interopRequireDefault(require("/www/wwwroot/node/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _request = _interopRequireDefault(require("@/utils/request"));
var _fileImage = _interopRequireDefault(require("@/components/fileImage.vue"));
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _vuex = require("vuex");
var _user = require("@/api/user");
var _formatMixin = require("@/utils/formatMixin");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var _default = exports.default = {
  components: {
    FileImage: _fileImage.default,
    Pagination: _index.default
  },
  mixins: [_formatMixin.formatMixin],
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['name', 'perms'])), {}, {
    // isAdmin
    // 如果perms包含 admin 字段 即为管理员，则返回true  否则为false
    isAdmin: function isAdmin() {
      return this.perms.includes('admin');
    }
  }),
  data: function data() {
    return {
      document: {},
      attachments: [],
      listLoading: false,
      orderList: {
        count: 0
      },
      moneyList: {
        count: 0
      },
      // 充值余额流水
      listQuery: {
        page: 1,
        page_size: 10
      },
      // 修改余额form
      accoutform: {
        sumcharge: '',
        recharge: 0 // 充值余额
      },
      // 余额提现form
      accoutform1: {
        sumcharge: '',
        withdrawal: 0 //提现余额
      },
      dialogVisibleAccount: false,
      //控制修改余额form
      dialogVisibleAccount1: false,
      //控制余额提现form
      dialogVisibleshop: false,
      // 控制修改商家信息form
      // 保存从服务器获取的修改收费form数据,开关数据也在里面，用于展示
      chargeform: {
        contract_money: '',
        // 合同费金额
        bear_money: '',
        // 代扣手续费
        rate: '',
        // 订单服务费
        is_contract: '',
        //合同
        is_bear: '',
        //代扣
        is_hand: '',
        //1% 订单服务费
        is_day_nums: '' // 一天多期划扣模式
      },
      dialogVisibleCharge: false,
      //控制修改收费form,
      // 以下为商家收费配置3个开关的值
      value1: true,
      value2: true,
      value3: true,
      // 收集修改收费form数据，提交给后端
      moneyform: {
        contract_money: '',
        // 合同费金额
        bear_money: '',
        // 代扣手续费
        rate: '' // 订单服务费
      },
      // 商家基础信息
      shopInfo: {},
      // 修改商家基础信息form
      shopform: {
        company_name: '',
        address: '',
        scc: '',
        product_name: '',
        email: '',
        status: '',
        alipay: '',
        sealId: '',
        aa_id: '',
        bb_id: '',
        cc_id: ''
      },
      isRequesting: false,
      // 控制按钮是否禁用的标志位
      lastExecutedTime: 0 // 上次执行的时间
    };
  },
  created: function created() {
    this.pk = this.$route.query.id;
    // 获取商家数据
    this.getList();
    this.getList1();
    // 获取充值余额流水
    this.getMoneyList();
    // 获取商家收费配置数据
    this.getCharge();
  },
  methods: {
    // 获取商家信息数据——done
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _request.default)({
        url: "/Shop/".concat(this.pk, "/"),
        method: 'get'
      }).then(function (response) {
        if (response.data) {
          _this.document = response.data;
          // this.shopform = response.data
          // 获取商家余额
          _this.getMoney();
        }
        _this.listLoading = false;
      });
      // 获取商家照片
      (0, _request.default)({
        url: "/ShopFile/",
        method: 'get',
        params: {
          'shop': this.pk
        }
      }).then(function (response) {
        if (response.data) {
          _this.attachments = response.data.results;
        }
      });
    },
    // 获取商家信息数据——新接口
    getList1: function getList1() {
      var _this2 = this;
      this.listLoading = true;
      (0, _request.default)({
        url: "/system/customer/getInfoAll/",
        method: 'get',
        params: {
          shop_id: this.pk
        }
      }).then(function (res) {
        _this2.shopInfo = res.data;
        _this2.shopform.company_name = res.data.company_name;
        _this2.shopform.address = res.data.address;
        _this2.shopform.scc = res.data.scc;
        _this2.shopform.product_name = res.data.product_name;
        _this2.shopform.email = res.data.email;
        _this2.shopform.status = res.data.status;
        _this2.shopform.alipay = res.data.customer.alipay;
        _this2.shopform.sealId = res.data.customer.sealId;
        _this2.listLoading = false;
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    // 页头--返回上级页面——done
    goBack: function goBack() {
      this.$router.go(-1);
    },
    // 获取商家余额——done
    getMoney: function getMoney() {
      var _this3 = this;
      (0, _request.default)({
        url: "/Customer/".concat(this.document.customer, "/"),
        method: 'get',
        params: {
          shop: this.$user.data.shop
        }
      }).then(function (response) {
        if (response.data) {
          _this3.accoutform.sumcharge = response.data.real_money;
          _this3.accoutform1.sumcharge = response.data.real_money;
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 获取余额流水——done
    getMoneyList: function getMoneyList() {
      var _this4 = this;
      this.listLoading = true;
      // 王工封装
      // type 1为充值余额流水  type 2 为支付宝账号流水
      (0, _request.default)({
        url: "/system/order/getUserRecord/",
        method: 'put',
        data: {
          shop_id: this.pk,
          type: 1,
          page: this.listQuery.page,
          page_size: this.listQuery.page_size,
          is_pay: 1
        }
      }).then(function (response) {
        if (response.data) {
          _this4.moneyList = response.data.data;
        }
        _this4.listLoading = false;
      });
    },
    // 修改余额保存，加限制——done
    onSubmitAccount: function onSubmitAccount() {
      var _this5 = this;
      var currentTime = Date.now();
      if (currentTime - this.lastExecutedTime > 2000) {
        if (this.accoutform.recharge < 1) {
          this.$message.error('最少1元');
          return;
        }
        this.isRequesting = true; // 禁用按钮
        // 发请求提交数据给后端
        (0, _request.default)({
          url: "/system/user/money/",
          method: 'put',
          data: {
            customer: this.document.customer,
            money: this.accoutform.recharge
          }
        }).then(function (response) {
          if (response.code == 200) {
            _this5.dialogVisibleAccount = false;
            _this5.accoutform.recharge = 0;
            _this5.getList(); //重新获取数据
            _this5.getMoneyList(); // 重新获取充值余额流水
            _this5.isRequesting = false; // 启用按钮
            _this5.lastExecutedTime = currentTime;
          }
        }).catch(function (error) {
          console.log(error);
        });
      } else {
        this.$message.error('请勿频繁操作');
        this.lastExecutedTime = currentTime;
      }
    },
    // 余额提现保存，加限制——done
    onSubmitAccount1: function onSubmitAccount1() {
      var _this6 = this;
      var currentTime = Date.now();
      if (currentTime - this.lastExecutedTime > 2000) {
        if (this.accoutform1.withdrawal < 1) {
          this.$message.error('最少1元');
          return;
        }
        this.isRequesting = true; // 禁用按钮
        // 发请求提交数据给后端
        (0, _request.default)({
          url: "/system/user/moneyDec/",
          method: 'put',
          data: {
            customer: this.document.customer,
            money: this.accoutform1.withdrawal
          }
        }).then(function (response) {
          if (response.code == 200) {
            _this6.dialogVisibleAccount1 = false;
            _this6.accoutform.withdrawal = 0;
            _this6.getList(); //重新获取数据
            _this6.getMoneyList(); // 重新获取充值余额流水
            _this6.isRequesting = false; // 启用按钮
            _this6.lastExecutedTime = currentTime;
          }
        }).catch(function (error) {
          console.log(error);
        });
      } else {
        this.$message.error('请勿频繁操作');
        this.lastExecutedTime = currentTime;
      }
    },
    // 获取商家收费配置数据——done
    getCharge: function getCharge() {
      var _this7 = this;
      (0, _request.default)({
        url: "/system/shopConfig/getConfig/",
        method: 'get',
        params: {
          // shop_id: this.$user.data.shop,
          shop_id: this.pk
        }
      }).then(function (response) {
        if (response.data) {
          _this7.chargeform = response.data;
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 修改收费保存——done
    onSubmitCharge: function onSubmitCharge() {
      var _this8 = this;
      // 提交数据给后端
      (0, _request.default)({
        url: "/system/shopConfig/updateConfig/",
        method: 'put',
        data: {
          shop_id: this.pk,
          field: 'value',
          value: JSON.stringify(this.moneyform)
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this8.dialogVisibleCharge = false;
          _this8.getCharge(); //重新获取数据
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 收费开关1change——done
    item1Change: function item1Change(e) {
      var _this9 = this;
      // 1.修改开关的值
      this.chargeform.is_contract = e;
      // 2.提交数据给后端
      (0, _request.default)({
        url: "/system/shopConfig/updateConfig/",
        method: 'put',
        data: {
          // shop_id: this.$user.data.shop,
          shop_id: this.pk,
          field: 'is_contract',
          value: this.chargeform.is_contract
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this9.getCharge(); //重新获取数据
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 收费开关2change——done
    item3Change: function item3Change(e) {
      var _this10 = this;
      // 1.修改开关的值
      this.chargeform.is_bear = e;
      // 2.提交数据给后端
      (0, _request.default)({
        url: "/system/shopConfig/updateConfig/",
        method: 'put',
        data: {
          shop_id: this.pk,
          field: 'is_bear',
          value: this.chargeform.is_bear
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this10.getCharge(); //重新获取数据
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 收费开关3change——done
    item2Change: function item2Change(e) {
      var _this11 = this;
      // 1.修改开关的值
      this.chargeform.is_hand = e;
      // 2.提交数据给后端
      (0, _request.default)({
        url: "/system/shopConfig/updateConfig/",
        method: 'put',
        data: {
          shop_id: this.pk,
          field: 'is_hand',
          value: this.chargeform.is_hand
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this11.getCharge(); //重新获取数据
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    item4Change: function item4Change(e) {
      var _this12 = this;
      // 1.修改开关的值
      this.chargeform.is_day_nums = e;
      // 2.提交数据给后端
      (0, _request.default)({
        url: "/system/shopConfig/updateConfig/",
        method: 'put',
        data: {
          shop_id: this.pk,
          field: 'is_day_nums',
          value: this.chargeform.is_day_nums
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this12.getCharge(); //重新获取数据
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 修改商家信息保存——done
    onSubmitShop: function onSubmitShop() {
      var _this13 = this;
      // 提交数据给后端
      (0, _request.default)({
        url: "/system/customer/updateInfo/",
        method: 'put',
        data: (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
          alipay: this.shopform.alipay,
          company_name: this.shopform.company_name,
          address: this.shopform.address,
          scc: this.shopform.scc,
          product_name: this.shopform.product_name,
          email: this.shopform.email,
          status: this.shopform.status
        }, "alipay", this.shopform.alipay), "sealId", this.shopform.sealId), "aa_id", this.shopform.aa_id), "bb_id", this.shopform.bb_id), "cc_id", this.shopform.cc_id), "shop_id", this.pk)
      }).then(function (response) {
        if (response.code == 200) {
          _this13.dialogVisibleshop = false;
          _this13.getList1(); //重新获取商家数据
        }
      }).catch(function (error) {
        console.log(error);
      });
    }
  }
};