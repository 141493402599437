"use strict";

var _interopRequireDefault = require("/www/wwwroot/node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
// import { validUsername } from '@/utils/validate'
var _default = exports.default = {
  name: 'Register',
  components: {},
  data: function data() {
    return {
      action: '/api/safe/posts/',
      document: {},
      loading: false,
      rules: {
        username: [{
          required: true,
          message: '请输入账户名',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 20 个字符以内',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
        kefu_mobile: [{
          required: true,
          message: '请输入客服电话',
          trigger: 'blur'
        }],
        // 新增手机号和社会信息代码校验
        person_mobile: [{
          required: true,
          message: '请填写法人电话',
          trigger: 'blur'
        }, {
          pattern: /^1[3456789]\d{9}$/,
          message: '手机号格式错误',
          trigger: 'blur'
        }],
        company_scc: [{
          required: true,
          message: '请填写公司统一社会信用代码',
          trigger: 'blur'
        }, {
          max: 18,
          message: '最多18位',
          trigger: 'blur'
        }],
        sealId: [{
          required: true,
          message: '请填写公司E签宝合同章号',
          trigger: 'blur'
        }],
        person_card_code: [{
          required: true,
          message: '请填写法人身份证',
          trigger: 'blur'
        }, {
          pattern: /^\d{17}[\dX]$/,
          message: '身份证格式错误',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    var registerDefault = this.$cookies.get('pre-register');
    if (registerDefault !== null) {
      console.log(registerDefault);
      this.document = registerDefault;
    } else {
      this.initData();
    }
    this.action = "".concat(process.env.VUE_APP_BASE_API, "/safe/posts/");
  },
  methods: {
    autoSave: function autoSave() {
      this.$cookies.set('pre-register', JSON.stringify(this.document));
    },
    clearCookie: function clearCookie() {
      var _this = this;
      this.$confirm('确认重置添加的信息？一旦重置数据无法找回', '警告', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'error'
      }).then(function () {
        _this.initData();
      });
    },
    initData: function initData() {
      var _this2 = this;
      this.document = {
        recharge: '预缴费',
        company_picture: [],
        person_picture: []
      };
      this.$cookies.set('pre-register', JSON.stringify(this.document));
      this.$nextTick(function () {
        _this2.$refs['Form'].clearValidate();
      });
    },
    submit: function submit() {
      var _this3 = this;
      this.$confirm('确认提交入驻申请？需等待审核人员审核，一般会在3个工作日内回复', '警告', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'error'
      }).then(function () {
        _this3.loading = true;
        _this3.$refs['Form'].validate(function (valid) {
          if (valid) {
            (0, _request.default)({
              url: '/anon/pre_register',
              method: 'post',
              data: _this3.document
            }).then(function (response) {
              _this3.loading = false;
              debugger;
              if (response.code === 0) {
                _this3.$message({
                  message: response.msg,
                  type: 'warning'
                });
              } else {
                _this3.$message({
                  message: '提交成功,等待审核',
                  type: 'success'
                });
                _this3.initData();
                _this3.$router.push("/login");
              }
            }).catch(function () {
              _this3.loading = false;
            });
          } else {
            _this3.loading = false;
            _this3.$message({
              message: '信息有误，请修改后重新提交',
              type: 'error'
            });
          }
        });
      });
    }
  }
};