"use strict";

var _interopRequireDefault = require("/www/wwwroot/node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("/www/wwwroot/node/node_modules/core-js/modules/es6.array.iterator.js");
require("/www/wwwroot/node/node_modules/core-js/modules/es6.promise.js");
require("/www/wwwroot/node/node_modules/core-js/modules/es6.object.assign.js");
require("/www/wwwroot/node/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("@/icons");
require("@/permission");
var _index3 = _interopRequireDefault(require("@/directive/el-table/index"));
var _vueCookies = _interopRequireDefault(require("vue-cookies"));
var _themeService = _interopRequireDefault(require("./service/themeService"));
// A modern alternative to CSS resets

// global css

// icon
// permission control

_vue.default.use(_index3.default);
_vue.default.use(_vueCookies.default);
_vue.default.use(_themeService.default);

// set ElementUI lang to EN
// Vue.use(ElementUI, { locale })
// 如果想要中文版 element-ui，按如下方式声明
_vue.default.use(_elementUi.default, {
  size: 'medium'
});
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});