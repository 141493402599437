"use strict";

var _interopRequireDefault = require("/www/wwwroot/node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createPerm = createPerm;
exports.deletePerm = deletePerm;
exports.getPermAll = getPermAll;
exports.updatePerm = updatePerm;
var _request = _interopRequireDefault(require("@/utils/request"));
function getPermAll() {
  return (0, _request.default)({
    url: '/system/permission/',
    method: 'get'
  });
}
function createPerm(data) {
  return (0, _request.default)({
    url: '/system/permission/',
    method: 'post',
    data: data
  });
}
function updatePerm(id, data) {
  return (0, _request.default)({
    url: "/system/permission/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deletePerm(id) {
  return (0, _request.default)({
    url: "/system/permission/".concat(id, "/"),
    method: 'delete'
  });
}