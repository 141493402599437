"use strict";

var _interopRequireDefault = require("/www/wwwroot/node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _defineProperty2 = _interopRequireDefault(require("/www/wwwroot/node/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
var _request = _interopRequireDefault(require("@/utils/request"));
var _formatMixin = require("@/utils/formatMixin");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var _default = exports.default = {
  name: 'Dashboard',
  components: {},
  mixins: [_formatMixin.formatMixin],
  data: function data() {
    return {
      data: null,
      money_hkhk_tj: 0,
      money_htshc_tj: 0,
      money_yetx_tj: 0,
      money_yczf_tj: 0,
      money_qita_tj: 0,
      overdue_num: 0,
      overdue_all_money: 0,
      overdue_money: 0,
      value1: '',
      listLoading: false
    };
  },
  created: function created() {
    // 请求index数据
    this.getIndexData();
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['name', 'perms'])), {}, {
    // isAdmin
    // 如果perms包含 admin 字段 即为管理员，则返回true  否则为false
    isAdmin: function isAdmin() {
      return this.perms.includes('admin');
    },
    start_time: function start_time() {
      return this.value1[0] ? this.formatDate(this.value1[0]) : this.formatDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    },
    end_time: function end_time() {
      return this.value1[1] ? this.formatDate(this.value1[1]) : this.formatDate(new Date());
    }
  }),
  methods: {
    // 格式化日期
    formatDate: function formatDate(date) {
      var year = date.getFullYear();
      var month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要加1
      var day = String(date.getDate()).padStart(2, '0');
      return "".concat(year, "-").concat(month, "-").concat(day);
    },
    getMoneyReal2: function getMoneyReal2() {
      var _this = this;
      (0, _request.default)({
        url: "/system/order/get_statics_ab/",
        method: 'put',
        data: {
          type: 1,
          shop_id: this.$user.data.shop,
          end_time: this.end_time,
          start_time: this.start_time
        }
      }).then(function (response) {
        console.log(response);
        if (response.data) {
          _this.overdue_num = response.data.money;
          _this.overdue_all_money = response.data.money1;
          _this.overdue_money = response.data.money2;
          // if(type==1){
          //     this.money_hkhk_tj=response.data.money
          // }
          // if(type==2){
          //     this.money_htshc_tj=response.data.money
          // }
          // if(type==3){
          //     this.money_yczf_tj=response.data.money
          // }
          // if(type==4){
          //     this.money_yetx_tj=response.data.money
          // }
          // if(type==5){
          //     this.money_qita_tj=response.data.money
          // }
          // this.orderList = response.data
          // this.orderList = this.orderList.reverse()
        }
        _this.listLoading = false;
      });
    },
    getMoneyReal: function getMoneyReal(type) {
      var _this2 = this;
      (0, _request.default)({
        url: "/system/order/get_statics_bc/",
        method: 'put',
        data: {
          type: type,
          shop_id: this.$user.data.shop
        }
      }).then(function (response) {
        if (response.data) {
          if (type == 1) {
            _this2.money_hkhk_tj = response.data.money;
          }
          if (type == 2) {
            _this2.money_htshc_tj = response.data.money;
          }
          if (type == 3) {
            _this2.money_yczf_tj = response.data.money;
          }
          if (type == 4) {
            _this2.money_yetx_tj = response.data.money;
          }
          if (type == 5) {
            _this2.money_qita_tj = response.data.money;
          }
          // this.orderList = response.data
          // this.orderList = this.orderList.reverse()
        }
        _this2.listLoading = false;
      });
    },
    // 获取数据
    getIndexData: function getIndexData() {
      var _this3 = this;
      this.listLoading = true;
      (0, _request.default)({
        url: "/dashboard/stat2",
        method: 'post',
        data: {
          end_time: this.end_time,
          start_time: this.start_time
        }
      }).then(function (response) {
        if (response.data) {
          _this3.data = response.data;
          _this3.$nextTick(function () {
            _this3.init = true;
          });
        }
        _this3.listLoading = false;
        _this3.getMoneyReal2();
      });
    },
    // 重置数据
    resetData: function resetData() {
      this.value1 = '';
      this.getIndexData();
    }
  }
};