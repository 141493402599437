"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "dashboard"
  }, [_c("el-container", [_c("div", {
    staticClass: "top_box"
  }, [_c("div", {
    staticClass: "name"
  }, [_vm._v("\n        Hi " + _vm._s(_vm.name) + "，"), _c("span", {
    staticStyle: {
      "font-size": "24px",
      "margin-left": "10px"
    }
  }, [_vm._v("欢迎回来 "), _c("i", {
    staticClass: "el-icon-message-solid",
    staticStyle: {
      color: "#e7b139",
      "font-size": "28px",
      "margin-right": "10px"
    }
  })])]), _vm._v(" "), _c("div", [_c("el-date-picker", {
    attrs: {
      type: "daterange",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期"
    },
    model: {
      value: _vm.value1,
      callback: function callback($$v) {
        _vm.value1 = $$v;
      },
      expression: "value1"
    }
  }), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.getIndexData
    }
  }, [_vm._v("查看")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.resetData
    }
  }, [_vm._v("重置")])], 1)]), _vm._v(" "), _vm.data.length == 0 ? _c("el-skeleton") : _vm._e(), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isAdmin,
      expression: "isAdmin"
    }],
    staticClass: "card1"
  }, [_c("el-row", [_c("h2", {
    staticClass: "cardTitle",
    staticStyle: {
      "margin-left": "15px"
    }
  }, [_vm._v("收益概览")]), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme"
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-s-data item_icon",
    staticStyle: {
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.contract_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("合同费收入")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme"
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-notebook-2 item_icon",
    staticStyle: {
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.rate_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("订单服务费收入")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme"
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-coin item_icon",
    staticStyle: {
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.hkh_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("划扣手续费收入")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme"
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-takeaway-box item_icon",
    staticStyle: {
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.pay_sum, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("划扣流水")])])])])], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme"
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-s-data item_icon",
    staticStyle: {
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.pay_all_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("支付订单金额")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme"
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-notebook-2 item_icon",
    staticStyle: {
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.rate_money_all / 100, 2)) + "元\n                ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("订单服务费抽成（0.01）")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme"
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-coin item_icon",
    staticStyle: {
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum((_vm.data.hkh_money + _vm.data.pay_sum) / 1000 * 7, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("划扣抽成（0.007）")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme"
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-takeaway-box item_icon",
    staticStyle: {
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(_vm.data.ensign_sum) + "笔")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("签约订单数")])])])])], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme"
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-s-data item_icon",
    staticStyle: {
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.hkhd_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("按时划扣回款金额")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme"
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-notebook-2 item_icon",
    staticStyle: {
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(_vm.data.hkhd_num) + "期")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("按时划扣回款期数")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme"
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-coin item_icon",
    staticStyle: {
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.hkhy_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("逾期划扣回款金额")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme"
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-takeaway-box item_icon",
    staticStyle: {
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(_vm.data.hkhy_num) + "期")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("逾期划扣回款期数")])])])])], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("h2", {
    staticClass: "cardTitle",
    staticStyle: {
      "margin-left": "15px",
      "margin-bottom": "20px"
    }
  }, [_vm._v("店铺概览")]), _vm._v(" "), _c("div", {
    staticClass: "subtitle"
  }, [_vm._v("\n        关键指标")]), _vm._v(" "), _c("el-row", {
    staticStyle: {
      "border-bottom": "1px solid #eee",
      margin: "0 20px",
      padding: "20px 0"
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme1"
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-coin item_icon",
    staticStyle: {
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.all_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("订单总值")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme1"
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-takeaway-box item_icon",
    staticStyle: {
      padding: "10px",
      "background-color": "#f2ba61"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.pay_sum, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("收到划扣流水")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme1"
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-s-data item_icon",
    staticStyle: {
      padding: "10px",
      "background-color": "#8590ff"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(_vm.data.pay_num) + "笔")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("已支付订单")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme1",
    staticStyle: {
      "border-right": "3px solid #fff"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-folder-checked item_icon",
    staticStyle: {
      padding: "10px",
      "background-color": "#65cfac"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.alipay_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray",
      "font-size": "14px"
    }
  }, [_vm._v("可提现金额(低于50元不可提现)")])])])])], 1), _vm._v(" "), _c("el-row", [_c("div", {
    staticClass: "subtitle",
    staticStyle: {
      "margin-bottom": "20px",
      "margin-top": "20px"
    }
  }, [_vm._v("\n          详细数据")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "carditem"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("\n              投诉数据\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "info2"
  }, [_c("div", {
    staticClass: "info2top"
  }, [_c("i", {
    staticClass: "el-icon-pie-chart item_icon desktop-view1"
  }), _vm._v("投诉订单 ")]), _vm._v(" "), _c("div", {
    staticClass: "info2bottom"
  }, [_vm._v("\n                  " + _vm._s(_vm.data.ts_num) + "个\n                ")])]), _vm._v(" "), _c("div", {
    staticClass: "info2"
  }, [_c("div", {
    staticClass: "info2top"
  }, [_c("i", {
    staticClass: "el-icon-folder item_icon desktop-view1"
  }), _vm._v("代扣投诉")]), _vm._v(" "), _c("div", {
    staticClass: "info2bottom"
  }, [_vm._v("                \n                  " + _vm._s(_vm.data.dk_ts_num) + "个\n                ")])]), _vm._v(" "), _c("div", {
    staticClass: "info2"
  }, [_c("div", {
    staticClass: "info2top"
  }, [_c("i", {
    staticClass: "el-icon-bell item_icon desktop-view1"
  }), _vm._v("代扣投诉率")]), _vm._v(" "), _c("div", {
    staticClass: "info2bottom"
  }, [_vm._v("                   \n                  " + _vm._s(_vm.data.dk_all_num) + "%\n                ")])])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "carditem"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("\n              核心指标\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "info2"
  }, [_c("div", {
    staticClass: "info2top"
  }, [_c("i", {
    staticClass: "el-icon-pie-chart item_icon desktop-view1"
  }), _vm._v("正常订单")]), _vm._v(" "), _c("div", {
    staticClass: "info2bottom"
  }, [_vm._v("            \n                  " + _vm._s(_vm.data.zc_num) + "个\n                ")])]), _vm._v(" "), _c("div", {
    staticClass: "info2"
  }, [_c("div", {
    staticClass: "info2top"
  }, [_c("i", {
    staticClass: "el-icon-bell item_icon desktop-view1"
  }), _vm._v("完成扣款订单")]), _vm._v(" "), _c("div", {
    staticClass: "info2bottom"
  }, [_vm._v("            \n                  " + _vm._s(_vm.data.dk_num) + "个\n                ")])]), _vm._v(" "), _c("div", {
    staticClass: "info2"
  }, [_c("div", {
    staticClass: "info2top"
  }, [_c("i", {
    staticClass: "el-icon-folder item_icon desktop-view1"
  }), _vm._v("待归还")]), _vm._v(" "), _c("div", {
    staticClass: "info2bottom"
  }, [_vm._v("             \n                  " + _vm._s(_vm.data.dgh_num) + "个\n                ")])]), _vm._v(" "), _c("div", {
    staticClass: "info2"
  }, [_c("div", {
    staticClass: "info2top"
  }, [_c("i", {
    staticClass: "el-icon-bell item_icon desktop-view1"
  }), _vm._v("已支付新订单")]), _vm._v(" "), _c("div", {
    staticClass: "info2bottom"
  }, [_vm._v("            \n                  " + _vm._s(_vm.data.pay_num) + "个\n                ")])]), _vm._v(" "), _c("div", {
    staticClass: "info2"
  }, [_c("div", {
    staticClass: "info2top"
  }, [_c("i", {
    staticClass: "el-icon-pie-chart item_icon desktop-view1"
  }), _vm._v("待支付新订单")]), _vm._v(" "), _c("div", {
    staticClass: "info2bottom"
  }, [_vm._v("             \n                  " + _vm._s(_vm.data.to_pay_num) + "个\n                ")])]), _vm._v(" "), _c("div", {
    staticClass: "info2"
  }, [_c("div", {
    staticClass: "info2top"
  }, [_c("i", {
    staticClass: "el-icon-folder item_icon desktop-view1"
  }), _vm._v("待签约新订单")]), _vm._v(" "), _c("div", {
    staticClass: "info2bottom"
  }, [_vm._v("               \n                  " + _vm._s(_vm.data.to_deduct_num) + "个\n                ")])])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "carditem"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("\n              重点关注数据\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "info2"
  }, [_c("div", {
    staticClass: "info2top"
  }, [_c("i", {
    staticClass: "el-icon-pie-chart item_icon desktop-view1"
  }), _vm._v("续费订单")]), _vm._v(" "), _c("div", {
    staticClass: "info2bottom"
  }, [_vm._v("                 \n                  " + _vm._s(_vm.data.xf_num) + "个\n                ")])]), _vm._v(" "), _c("div", {
    staticClass: "info2"
  }, [_c("div", {
    staticClass: "info2top"
  }, [_c("i", {
    staticClass: "el-icon-bell item_icon desktop-view1"
  }), _vm._v("已支付续费订单")]), _vm._v(" "), _c("div", {
    staticClass: "info2bottom"
  }, [_vm._v("                 \n                  " + _vm._s(_vm.data.xf_pay_num) + "个\n                ")])]), _vm._v(" "), _c("div", {
    staticClass: "info2"
  }, [_c("div", {
    staticClass: "info2top"
  }, [_c("i", {
    staticClass: "el-icon-pie-chart item_icon desktop-view1"
  }), _vm._v("代扣续费订单")]), _vm._v(" "), _c("div", {
    staticClass: "info2bottom"
  }, [_vm._v("                  \n                  " + _vm._s(_vm.data.xf_dk_num) + "个\n                ")])]), _vm._v(" "), _c("div", {
    staticClass: "info2"
  }, [_c("div", {
    staticClass: "info2top"
  }, [_c("i", {
    staticClass: "el-icon-pie-chart item_icon desktop-view1"
  }), _vm._v("已完成订单")]), _vm._v(" "), _c("div", {
    staticClass: "info2bottom"
  }, [_vm._v("                  \n                  " + _vm._s(_vm.data.h_num) + "个\n                ")])]), _vm._v(" "), _c("div", {
    staticClass: "info2"
  }, [_c("div", {
    staticClass: "info2top"
  }, [_c("i", {
    staticClass: "el-icon-folder item_icon desktop-view1"
  }), _vm._v("已完成")]), _vm._v(" "), _c("div", {
    staticClass: "info2bottom"
  }, [_vm._v("                \n                  " + _vm._s(_vm.data.ywc_num) + "个\n                ")])]), _vm._v(" "), _c("div", {
    staticClass: "info2"
  }, [_c("div", {
    staticClass: "info2top"
  }, [_c("i", {
    staticClass: "el-icon-folder item_icon desktop-view1"
  }), _vm._v("逾期订单")]), _vm._v(" "), _c("div", {
    staticClass: "info2bottom"
  }, [_vm._v("                  \n                  " + _vm._s(_vm.data.yq_num) + "个\n                ")])]), _vm._v(" "), _c("div", {
    staticClass: "info2"
  }, [_c("div", {
    staticClass: "info2top"
  }, [_c("i", {
    staticClass: "el-icon-bell item_icon desktop-view1"
  }), _vm._v("违约订单")]), _vm._v(" "), _c("div", {
    staticClass: "info2bottom"
  }, [_vm._v("                  \n                  " + _vm._s(_vm.data.vy_num) + "个\n                ")])])])])])], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;