"use strict";

var _interopRequireDefault = require("/www/wwwroot/node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _defineProperty2 = _interopRequireDefault(require("/www/wwwroot/node/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _request = _interopRequireDefault(require("@/utils/request"));
var _request2 = _interopRequireDefault(require("@/utils/request4"));
var _RestTable = _interopRequireDefault(require("@/components/RestTable.vue"));
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _formatMixin = require("@/utils/formatMixin");
var _fileSaver = _interopRequireDefault(require("file-saver"));
var _encrypt = require("@/utils/encrypt");
var _vuex = require("vuex");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var _default = exports.default = {
  components: {
    RestTable: _RestTable.default,
    Pagination: _index.default
  },
  mixins: [_formatMixin.formatMixin],
  data: function data() {
    return {
      // 顶部搜索框form数据
      formInline: {
        keyword: '',
        //搜索关键词
        start_time: '',
        remark: '',
        end_time: '',
        gmoney: '',
        lmoney: ''
      },
      lastExecutedTime: 0,
      // 上次执行的时间
      dialogFormVisible: false,
      datenum: [],
      money: 0,
      // 商家余额
      moneyList: {
        count: 0
      },
      // 划扣流水
      listQuery: {
        page: 1,
        page_size: 10
      },
      listLoading: false,
      msgBtnTxt: '获取验证码',
      msg_code: '',
      pk: 0,
      alipay_name: '',
      alipay_login: ''
    };
  },
  created: function created() {
    this.getMoneyList();
    this.chheckAipay();
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['name', 'perms'])), {}, {
    // isAdmin
    // 如果perms包含 admin 字段 即为管理员，则返回true  否则为false
    isAdmin: function isAdmin() {
      return this.perms.includes('admin');
    },
    is_withdrawal: function is_withdrawal() {
      return this.perms.includes('withdrawal_ok');
    },
    start_time: function start_time() {
      return this.datenum[0] ? this.formatDate(this.datenum[0]) : '';
    },
    end_time: function end_time() {
      return this.datenum[1] ? this.formatDate(this.datenum[1]) : '';
    }
  }),
  methods: {
    chheckAipay: function chheckAipay() {
      var _this = this;
      (0, _request.default)({
        url: "/system/user/checkUserAlipay/",
        method: 'put',
        data: {
          shop_id: this.$user.data.shop
        }
      }).then(function (response) {
        if (response.data) {
          _this.alipay_name = response.data.alipay_name;
          _this.alipay_login = response.data.alipay_login;
        }
        _this.listLoading = false;
      });
    },
    // 格式化日期,需要加上时分秒的数据
    formatDate: function formatDate(date) {
      var year = date.getFullYear();
      var month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要加1
      var day = String(date.getDate()).padStart(2, '0');
      var hour = String(date.getHours()).padStart(2, '0');
      var minute = String(date.getMinutes()).padStart(2, '0');
      var second = String(date.getSeconds()).padStart(2, '0');
      return "".concat(year, "-").concat(month, "-").concat(day, " ").concat(hour, ":").concat(minute, ":").concat(second);
    },
    resetFilter: function resetFilter() {
      this.formInline.keyword = '';
      this.formInline.start_time = '';
      this.formInline.remark = '';
      this.formInline.end_time = '';
      this.formInline.gmoney = '';
      this.formInline.lmoney = '';
      this.datenum = [];
      this.getMoneyList();
    },
    // 搜索
    handleFilter: function handleFilter() {
      var _this2 = this;
      this.listLoading = true;
      // 王工封装
      // type 2 为支付宝账号流水
      (0, _request.default)({
        url: "/system/order/getFUserRecord/",
        method: 'put',
        data: {
          shop_id: this.$user.data.shop,
          type: 3,
          is_pay: 0,
          value: this.formInline.keyword,
          remark: this.formInline.remark,
          start_time: this.start_time,
          end_time: this.end_time,
          gmoney: this.formInline.gmoney,
          lmoney: this.formInline.lmoney
        }
      }).then(function (response) {
        if (response.data) {
          _this2.moneyList = response.data.data;
        }
        _this2.listLoading = false;
      });
    },
    DownExcel: function DownExcel() {
      var _this3 = this;
      this.listLoading = true;
      // 王工封装
      // type 2 为支付宝账号流水
      (0, _request2.default)({
        url: "/system/order/getUserRecordExcel/",
        method: 'put',
        responseType: 'blob',
        // 设置响应类型为 blob
        data: {
          shop_id: this.$user.data.shop,
          type: 3,
          value: this.formInline.keyword,
          remark: this.formInline.remark,
          start_time: this.start_time,
          end_time: this.end_time,
          gmoney: this.formInline.gmoney,
          lmoney: this.formInline.lmoney
        }
      }).then(function (response) {
        console.log(response.data, '文件');
        // if (response.data) {
        //   this.moneyList = response.data.data
        // }
        var blob = new Blob([response.data], {
          type: 'application/vnd.ms-excel'
        });
        _fileSaver.default.saveAs(blob, 'user_record.xlsx'); // 保存文件
        _this3.listLoading = false;
      });
    },
    // 获取划扣流水——done
    getMoneyList: function getMoneyList() {
      var _this4 = this;
      this.listLoading = true;
      // 王工封装
      // type 2 为支付宝账号流水
      (0, _request.default)({
        url: "/system/order/getFUserRecord/",
        method: 'put',
        data: {
          shop_id: this.$user.data.shop,
          type: 3,
          is_pay: 0,
          page: this.listQuery.page,
          page_size: this.listQuery.page_size,
          remark: this.formInline.remark,
          value: this.formInline.keyword,
          start_time: this.start_time,
          end_time: this.end_time,
          gmoney: this.formInline.gmoney,
          lmoney: this.formInline.lmoney
        }
      }).then(function (response) {
        if (response.data) {
          _this4.moneyList = response.data.data;
        }
        _this4.listLoading = false;
      });
    },
    approve: function approve(value) {
      this.dialogFormVisible = true;
      this.pk = value.pk;
    },
    getCode: function getCode() {
      var _this5 = this;
      //  1.验证手机号
      // 成功则发验证码
      // 参数加密
      var timestamp = Date.now().toString();
      var safe = (0, _encrypt.encrypt)('13017111117' + "&" + timestamp);
      // 发请求
      (0, _request.default)({
        url: 'anon/send_msg',
        method: 'post',
        data: {
          mobile: '13017111117',
          timestamp: timestamp,
          safe: safe
        }
      }).then(function (res) {
        _this5.$message.success('验证码发送成功');
        _this5.cycleMsg(60);
      });
    },
    // 倒计时
    cycleMsg: function cycleMsg(currentSecond) {
      var _this6 = this;
      if (currentSecond > 0) {
        this.msgBtnTxt = "".concat(currentSecond, "\u79D2");
        setTimeout(function () {
          return _this6.cycleMsg(currentSecond - 1);
        }, 1000);
      } else {
        this.msgBtnTxt = '获取验证码';
      }
    },
    clossPop: function clossPop() {
      var _this7 = this;
      var timestamp = Date.now().toString();
      var safe = (0, _encrypt.encrypt)('13017111117' + "&" + this.msg_code + "&" + timestamp);
      // 发请求
      (0, _request.default)({
        url: 'anon/check_msg',
        method: 'post',
        data: {
          mobile: '13017111117',
          msg_code: this.msg_code,
          timestamp: timestamp,
          safe: safe
        }
      }).then(function (res) {
        if (res.code == 200) {
          _this7.dialogFormVisible = false;
          _this7.approve1();
        }
      });
    },
    approve1: function approve1() {
      var _this8 = this;
      if (this.is_withdrawal || this.isAdmin) {
        var currentTime = Date.now();
        if (currentTime - this.lastExecutedTime > 2000) {
          // 发请求提交数据给后端
          (0, _request.default)({
            url: "/system/user/withdrawalF/",
            method: 'put',
            data: {
              id: this.pk
            }
          }).then(function (response) {
            if (response.code == 200) {
              _this8.getMoneyList(); // 刷新列表
              _this8.lastExecutedTime = currentTime;
            }
          }).catch(function (error) {
            console.log(error);
          });
        } else {
          this.$message.error('请勿频繁操作');
          this.lastExecutedTime = currentTime;
        }
      } else {
        this.$message({
          message: '您没有操作权限',
          type: 'warning'
        });
      }
    },
    setRecord: function setRecord() {
      var _this9 = this;
      (0, _request.default)({
        url: "/system/user/createRecord/",
        method: 'put',
        data: {
          shop_id: this.$user.data.shop
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this9.$message({
            message: '生成成功',
            type: 'success'
          });
          _this9.getMoneyList(); // 刷新列表
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    withMoneyOnly: function withMoneyOnly(value) {
      var _this10 = this;
      this.$confirm('确认已线下转账金额' + value.money + '?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        // 发请求提交数据给后端
        (0, _request.default)({
          url: "/system/user/withMoneyOnlyF/",
          method: 'put',
          data: {
            record_id: value.pk
          }
        }).then(function (response) {
          if (response.code == 200) {
            _this10.$message({
              message: '返点成功',
              type: 'success'
            });
            _this10.getMoneyList(); // 刷新列表
          }
        }).catch(function (error) {
          console.log(error);
        });
      }).catch(function () {
        _this10.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    }
  }
};