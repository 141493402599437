"use strict";

var _interopRequireDefault = require("/www/wwwroot/node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
var _user = require("@/api/user");
var _encrypt = require("@/utils/encrypt");
var _request = _interopRequireDefault(require("@/utils/request"));
// import { validUsername } from '@/utils/validate'
var _default = exports.default = {
  name: 'Login',
  data: function data() {
    // const validateUsername = (rule, value, callback) => {
    //   if (!validUsername(value)) {
    //     callback(new Error('请输入正确账号!'))
    //   } else {
    //     callback()
    //   }
    // }
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length != 4) {
        callback(new Error('验证码位数不对!'));
      } else {
        callback();
      }
    };
    return {
      msgBtnTxt: '获取验证码',
      loginForm: {
        username: '',
        password: '',
        mobile: '',
        msg_code: ''
      },
      loginRules: {
        mobile: [{
          required: true,
          trigger: 'blur',
          message: '请输入手机号'
        }],
        msg_code: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword,
          message: '请输入验证码'
        }]
      },
      document: {},
      loading: false,
      dialogVisible: false,
      passwordType: 'password',
      redirect: undefined
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  methods: {
    showPwd: function showPwd() {
      var _this = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    // 获取验证码
    getCode: function getCode() {
      var _this2 = this;
      //  1.验证手机号
      var reg = /^1[0-9]{10,10}$/;
      // 成功则发验证码
      if (reg.test(this.loginForm.mobile)) {
        // 参数加密
        var timestamp = Date.now().toString();
        var safe = (0, _encrypt.encrypt)(this.loginForm.mobile + "&" + timestamp);
        // 发请求
        (0, _request.default)({
          url: 'anon/send_msg',
          method: 'post',
          data: {
            mobile: this.loginForm.mobile,
            timestamp: timestamp,
            safe: safe
          }
        }).then(function (res) {
          _this2.$message.success('验证码发送成功');
          _this2.cycleMsg(60);
        });
        this.cycleMsg(60);
      } else {
        this.$message.error('请输入正确手机号');
      }
    },
    // 倒计时
    cycleMsg: function cycleMsg(currentSecond) {
      var _this3 = this;
      if (currentSecond > 0) {
        this.msgBtnTxt = "".concat(currentSecond, "\u79D2");
        setTimeout(function () {
          return _this3.cycleMsg(currentSecond - 1);
        }, 1000);
      } else {
        this.msgBtnTxt = '获取验证码';
      }
    },
    handleRegister: function handleRegister() {
      this.$router.push("/register");
    },
    handleLogin: function handleLogin() {
      var _this4 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this4.loading = true;
          _this4.$store.dispatch('user/login', _this4.loginForm).then(function (res) {
            _this4.$router.push({
              path: _this4.redirect || '/dashboard'
            });
            _this4.loading = false;
            // 去获取用户对应的商户
            (0, _user.getInfo)().then(function (response) {
              if (response.data) {
                _this4.shop = response.data.shop;
                _this4.$user.updateShop(response.data.shop);
                // 刷新整个页面
                window.location.reload();
              }
            });
          }).catch(function () {
            _this4.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};