"use strict";

var _interopRequireDefault = require("/www/wwwroot/node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _fileImage = _interopRequireDefault(require("@/components/fileImage.vue"));
var _default = exports.default = {
  components: {
    FileImage: _fileImage.default
  },
  data: function data() {
    return {
      document: {},
      attachments: [],
      listLoading: true
    };
  },
  created: function created() {
    this.pk = this.$route.query.id;
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      // 获取注册信息
      (0, _request.default)({
        url: "/PreUser/".concat(this.pk, "/"),
        method: 'get'
      }).then(function (response) {
        if (response.data) {
          _this.document = response.data;
        }
        _this.listLoading = false;
      });
      // 获取附件图片
      (0, _request.default)({
        url: "/PreUserFile/",
        method: 'get',
        params: {
          'pre_user': this.pk
        }
      }).then(function (response) {
        if (response.data) {
          _this.attachments = response.data.results;
        }
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    submitReport: function submitReport(commit) {
      var _this2 = this;
      (0, _request.default)({
        url: "/PreUser/report",
        method: 'post',
        data: {
          pid: this.pk,
          commit: commit
        }
      }).then(function (response) {
        _this2.getList();
      });
    }
  }
};