"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "dashboard",
    staticStyle: {
      "padding-bottom": "20px"
    }
  }, [_vm.isLoading ? _c("div", {
    staticClass: "fullscreen-loading"
  }, [_vm._m(0)]) : _c("div", {
    staticClass: "chartreport"
  }, [_c("el-page-header", {
    staticStyle: {
      margin: "10px 0"
    },
    attrs: {
      content: "详情页面"
    },
    on: {
      back: _vm.goBack
    }
  }), _vm._v(" "), _c("el-card", {
    staticClass: "info_card"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("人人履约报告"), _c("span", {
    staticClass: "time"
  }, [_vm._v("报告出具时间：" + _vm._s(_vm.formatTime(_vm.userInfo.create_time)))])]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "item_title",
    staticStyle: {
      "font-size": "28px"
    }
  }, [_vm._v(_vm._s(_vm.userInfo.name))]), _vm._v(" "), _c("el-tag", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.userInfo.is_xyhf == 1,
      expression: "userInfo.is_xyhf == 1"
    }],
    staticStyle: {
      "border-radius": "15px"
    },
    attrs: {
      type: "default",
      hit: ""
    }
  }, [_vm._v("先用后付")]), _vm._v(" "), _c("el-tag", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.userInfo.is_zj == 1,
      expression: "userInfo.is_zj == 1"
    }],
    staticStyle: {
      "border-radius": "15px"
    },
    attrs: {
      type: "success",
      hit: ""
    }
  }, [_vm._v("租机")]), _vm._v(" "), _c("el-tag", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.userInfo.is_wd == 1,
      expression: "userInfo.is_wd == 1"
    }],
    staticStyle: {
      "border-radius": "15px"
    },
    attrs: {
      type: "warning",
      hit: ""
    }
  }, [_vm._v("网贷")]), _vm._v(" "), _c("el-tag", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.userInfo.is_hszj == 1,
      expression: "userInfo.is_hszj == 1"
    }],
    staticStyle: {
      "border-radius": "15px"
    },
    attrs: {
      type: "danger",
      hit: ""
    }
  }, [_vm._v("回收中介")])], 1), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "item_title"
  }, [_vm._v("年龄：" + _vm._s(_vm.userInfo.ages))]), _vm._v(" "), _c("span", {
    staticClass: "item_title"
  }, [_vm._v("性别：" + _vm._s(_vm.userInfo.sex))]), _vm._v(" "), _c("span", [_vm._v("身份证号：" + _vm._s(_vm.userInfo.card_code))])]), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "item_title"
  }, [_vm._v("户籍所在地：" + _vm._s(_vm.userInfo.address))])]), _vm._v(" "), _c("div", {
    staticClass: "type"
  }, [_c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.type == 2,
      expression: "type == 2"
    }],
    staticClass: "img",
    attrs: {
      src: require("../../assets/wechaticon.png"),
      alt: ""
    }
  }), _vm._v(" "), _c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.type == 1,
      expression: "type == 1"
    }],
    staticClass: "img",
    attrs: {
      src: require("../../assets/alipayicon.png"),
      alt: ""
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      "font-size": "16px"
    }
  }, [_vm._v(_vm._s(_vm.time_des))])])]), _vm._v(" "), _c("div", {
    staticClass: "right"
  }, [_c("img", {
    attrs: {
      src: require("../../assets/bang.png"),
      alt: ""
    }
  })])])]), _vm._v(" "), _c("el-row", {
    staticClass: "chart_row",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/alllist.png"),
      alt: ""
    }
  }), _vm._v("全部账单内容")]), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "20px"
    }
  }, [_c("el-form", {
    attrs: {
      inline: true,
      model: _vm.formInline
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "关键词搜索"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入想要搜索的内容"
    },
    model: {
      value: _vm.formInline.keyword,
      callback: function callback($$v) {
        _vm.$set(_vm.formInline, "keyword", $$v);
      },
      expression: "formInline.keyword"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search",
      size: "small"
    },
    on: {
      click: _vm.getAllList
    }
  }, [_vm._v("搜索")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-refresh-right",
      size: "small"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v("重置")])], 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v("数据概览：单位元")]), _vm._v(" "), _c("div", [_vm._v("总收入(￥)："), _c("span", {
    staticStyle: {
      color: "red",
      "font-weight": "600"
    }
  }, [_vm._v(" " + _vm._s(_vm.allList.sumS))])]), _vm._v(" "), _c("div", [_vm._v(" 总支出(￥)："), _c("span", {
    staticStyle: {
      color: "red",
      "font-weight": "600"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.allList.sumZ, 2)))])]), _vm._v(" "), _c("div", [_vm._v("不计收支(￥)："), _c("span", {
    staticStyle: {
      color: "red",
      "font-weight": "600"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.allList.sumB, 2)))])]), _vm._v(" "), _c("div", [_vm._v("其他(￥)："), _c("span", {
    staticStyle: {
      color: "red",
      "font-weight": "600"
    }
  }, [_vm._v(_vm._s(_vm.allList.sumQ))])])]), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isAllLoading,
      expression: "isAllLoading"
    }],
    staticStyle: {
      margin: "15px 0"
    },
    attrs: {
      data: _vm.allList.results,
      stripe: "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "pay_time",
      label: "交易时间"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "money",
      label: "金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n                                    " + _vm._s(scope.row.money) + "元\n                                ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "order_des",
      label: "交易描述"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "order_from",
      label: "交易方",
      width: "width"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n                                  " + _vm._s(scope.row.order_from) + "\n                                  "), _c("el-button", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.isAdmin,
            expression: "isAdmin"
          }],
          attrs: {
            type: "primary",
            size: "small"
          },
          on: {
            click: function click($event) {
              return _vm.goAddShop(scope.row.order_from);
            }
          }
        }, [_vm._v("添加")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "pay_type",
      label: "交易类型",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "re_or_ex",
      label: "账单类型",
      width: "width"
    }
  })], 1), _vm._v(" "), _c("Pagination", {
    staticStyle: {
      "margin-top": "10px",
      "text-align": "center"
    },
    attrs: {
      total: _vm.allList.count,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.page_size,
      autoScroll: false
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.listQuery, "page_size", $event);
      },
      pagination: _vm.getAllList
    }
  })], 1)])], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "chart_row",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/day.png"),
      alt: ""
    }
  }), _vm._v("\n                        日活跃数据\n                        "), _c("div", {
    staticClass: "tips"
  }, [_vm._v("用户单天累计活跃次数(按小时统计)")])]), _vm._v(" "), _c("div", {
    staticClass: "desc"
  }, [_c("ul", [_c("li", [_c("span", [_vm._v("最高活跃次数：")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.max_hourly_totals) + "次")])]), _vm._v(" "), _c("li", [_c("span", [_vm._v("最低活跃次数：")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.min_hourly_totals) + "次")])])])]), _vm._v(" "), _c("BarChart", {
    ref: "barChart1",
    attrs: {
      option: _vm.timedata
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/month.png"),
      alt: ""
    }
  }), _vm._v("\n                        月活跃数据\n                        "), _c("div", {
    staticClass: "tips"
  }, [_vm._v("用户月累计活跃次数(按月统计)")])]), _vm._v(" "), _c("div", {
    staticClass: "desc"
  }, [_c("ul", [_c("li", [_c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.type == 1,
      expression: "type == 1"
    }]
  }, [_vm._v("支付宝活跃率")]), _vm._v(" "), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.type == 2,
      expression: "type == 2"
    }]
  }, [_vm._v("微信活跃率")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(this.formatNum(_vm.missing_percentage, 2)) + "%")])]), _vm._v(" "), _c("li", [_c("span", [_vm._v("最长未使用天数")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.longest_missing_sequence) + "天")])])])]), _vm._v(" "), _c("BarChart", {
    ref: "barChart2",
    attrs: {
      option: _vm.monthdata
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "chart_row",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/alllist.png"),
      alt: ""
    }
  }), _vm._v("月均收入/支出数据对比")]), _vm._v(" "), _c("LineChart", {
    ref: "lineChart1",
    attrs: {
      option: _vm.incomedata
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "chart_row",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/banklist.png"),
      alt: ""
    }
  }), _vm._v("\n                        银行卡列表\n                        "), _c("div", {
    staticClass: "tips"
  }, [_vm._v("统计用户储蓄卡和信用卡使用情况")])]), _vm._v(" "), _c("div", {
    staticClass: "desc"
  }, [_c("ul", [_c("li", [_c("span", [_vm._v("储蓄卡：")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.count) + "张")])]), _vm._v(" "), _c("li", [_c("span", [_vm._v("信用卡：")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.count1) + "张")])])])]), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "20px"
    }
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.bankList,
      border: "",
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "name",
      label: "银行名称",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "code",
      label: "银行卡尾号",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "num",
      label: "交易次数",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "per",
      label: "使用率",
      width: "width"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            type: "primary",
            effect: "plain"
          }
        }, [_vm._v(_vm._s(scope.row.per) + "%")])];
      }
    }])
  })], 1)], 1)])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/shoplist.png"),
      alt: ""
    }
  }), _vm._v("交易商户列表TOP15\n                        "), _c("div", {
    staticClass: "tips"
  }, [_vm._v("仅展示前15项数据")])]), _vm._v(" "), _c("el-tabs", {
    staticStyle: {
      padding: "0 20px"
    },
    on: {
      "tab-click": _vm.handleClick
    },
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "近7日",
      name: "first"
    }
  }, [_c("ShopTable", {
    attrs: {
      tabledata: _vm.formlist1
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "近15日",
      name: "second"
    }
  }, [_c("ShopTable", {
    attrs: {
      tabledata: _vm.formlist1
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "近1个月",
      name: "third"
    }
  }, [_c("ShopTable", {
    attrs: {
      tabledata: _vm.formlist1
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "近3个月",
      name: "fourth"
    }
  }, [_c("ShopTable", {
    attrs: {
      tabledata: _vm.formlist1
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "近6个月",
      name: "fifth"
    }
  }, [_c("ShopTable", {
    attrs: {
      tabledata: _vm.formlist1
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "近1年",
      name: "sixth"
    }
  }, [_c("ShopTable", {
    attrs: {
      tabledata: _vm.formlist1
    }
  })], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "chart_row",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/xianyong.png"),
      alt: ""
    }
  }), _vm._v("先用后付\n                    ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "0 20px"
    }
  }, [_c("div", {
    staticClass: "shop_box"
  }, _vm._l(_vm.shopNameList1, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "shop_item",
      on: {
        click: function click($event) {
          return _vm.clickShop1(item);
        }
      }
    }, [item.url != "" ? _c("div", {
      staticClass: "itemimg"
    }, [_c("img", {
      staticStyle: {
        width: "100%"
      },
      attrs: {
        src: item.url,
        alt: ""
      }
    })]) : _c("div", {
      staticClass: "noimg"
    }, [_c("div", [_vm._v("暂无")]), _vm._v(" "), _c("div", [_vm._v("图片")])]), _vm._v(" "), _c("div", {
      staticClass: "itemname",
      staticStyle: {
        "margin-top": "5px"
      }
    }, [_vm._v(_vm._s(item.name))])]);
  }), 0), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "20px 0"
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.shopList1.results,
      stripe: "",
      border: "",
      height: "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "pay_time",
      label: "交易时间"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "money",
      label: "金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n                                        " + _vm._s(scope.row.money) + "元\n                                    ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "order_des",
      label: "交易描述"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "order_from",
      label: "交易方",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "pay_type",
      label: "交易类型",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "re_or_ex",
      label: "账单类型",
      width: "width"
    }
  })], 1)], 1)])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/zulin.png"),
      alt: ""
    }
  }), _vm._v("租赁")]), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "0 20px"
    }
  }, [_c("div", {
    staticClass: "shop_box"
  }, _vm._l(_vm.shopNameList2, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "shop_item",
      on: {
        click: function click($event) {
          return _vm.clickShop2(item);
        }
      }
    }, [item.url != "" ? _c("div", {
      staticClass: "itemimg"
    }, [_c("img", {
      staticStyle: {
        width: "100%"
      },
      attrs: {
        src: item.url,
        alt: ""
      }
    })]) : _c("div", {
      staticClass: "noimg"
    }, [_c("div", [_vm._v("暂无")]), _vm._v(" "), _c("div", [_vm._v("图片")])]), _vm._v(" "), _c("div", {
      staticClass: "itemname",
      staticStyle: {
        "margin-top": "5px"
      }
    }, [_vm._v(_vm._s(item.name))])]);
  }), 0), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "20px 0"
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.shopList2.results,
      stripe: "",
      border: "",
      height: "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "pay_time",
      label: "交易时间"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "money",
      label: "金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n                                        " + _vm._s(scope.row.money) + "元\n                                    ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "order_des",
      label: "交易描述"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "order_from",
      label: "交易方",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "pay_type",
      label: "交易类型",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "re_or_ex",
      label: "账单类型",
      width: "width"
    }
  })], 1)], 1)])])], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "chart_row",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/wangdai.png"),
      alt: ""
    }
  }), _vm._v("网贷")]), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "0 20px"
    }
  }, [_c("div", {
    staticClass: "shop_box"
  }, _vm._l(_vm.shopNameList3, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "shop_item",
      on: {
        click: function click($event) {
          return _vm.clickShop3(item);
        }
      }
    }, [item.url != "" ? _c("div", {
      staticClass: "itemimg"
    }, [_c("img", {
      staticStyle: {
        width: "100%"
      },
      attrs: {
        src: item.url,
        alt: ""
      }
    })]) : _c("div", {
      staticClass: "noimg"
    }, [_c("div", [_vm._v("暂无")]), _vm._v(" "), _c("div", [_vm._v("图片")])]), _vm._v(" "), _c("div", {
      staticClass: "itemname",
      staticStyle: {
        "margin-top": "5px"
      }
    }, [_vm._v(_vm._s(item.name))])]);
  }), 0), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "20px 0"
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.shopList3.results,
      stripe: "",
      border: "",
      height: "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "pay_time",
      label: "交易时间"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "money",
      label: "金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n                                        " + _vm._s(scope.row.money) + "元\n                                    ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "order_des",
      label: "交易描述"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "order_from",
      label: "交易方",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "pay_type",
      label: "交易类型",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "re_or_ex",
      label: "账单类型",
      width: "width"
    }
  })], 1)], 1)])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/huishou.png"),
      alt: ""
    }
  }), _vm._v("\n                        回收中介\n                    ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "0 20px"
    }
  }, [_c("div", {
    staticClass: "shop_box"
  }, _vm._l(_vm.shopNameList4, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "shop_item",
      on: {
        click: function click($event) {
          return _vm.clickShop4(item);
        }
      }
    }, [item.url != "" ? _c("div", {
      staticClass: "itemimg"
    }, [_c("img", {
      staticStyle: {
        width: "100%"
      },
      attrs: {
        src: item.url,
        alt: ""
      }
    })]) : _c("div", {
      staticClass: "noimg"
    }, [_c("div", [_vm._v("暂无")]), _vm._v(" "), _c("div", [_vm._v("图片")])]), _vm._v(" "), _c("div", {
      staticClass: "itemname",
      staticStyle: {
        "margin-top": "5px"
      }
    }, [_vm._v(_vm._s(item.name))])]);
  }), 0), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "20px 0"
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.shopList4.results,
      stripe: "",
      border: "",
      height: "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "pay_time",
      label: "交易时间"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "money",
      label: "金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n                                        " + _vm._s(scope.row.money) + "元\n                                    ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "order_des",
      label: "交易描述"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "order_from",
      label: "交易方",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "pay_type",
      label: "交易类型",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "re_or_ex",
      label: "账单类型",
      width: "width"
    }
  })], 1)], 1)])])], 1)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "添加店铺",
      visible: _vm.dialogFormVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogFormVisible = $event;
      }
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, _vm._l(_vm.tag_list, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1), _vm._v(" "), _c("el-button", {
    staticClass: "button-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      click: _vm.addShop
    }
  }, [_vm._v("提交")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "custom-loading"
  }, [_c("img", {
    staticClass: "loading-image",
    staticStyle: {
      width: "600px"
    },
    attrs: {
      src: require("../../assets/2.gif"),
      alt: ""
    }
  })]);
}];
render._withStripped = true;