import { render, staticRenderFns } from "./ShopTable.vue?vue&type=template&id=27825007"
import script from "./ShopTable.vue?vue&type=script&lang=js"
export * from "./ShopTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/www/wwwroot/node/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('27825007')) {
      api.createRecord('27825007', component.options)
    } else {
      api.reload('27825007', component.options)
    }
    module.hot.accept("./ShopTable.vue?vue&type=template&id=27825007", function () {
      api.rerender('27825007', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/chart/ShopTable.vue"
export default component.exports