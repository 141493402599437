"use strict";

var _interopRequireDefault = require("/www/wwwroot/node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = checkPermission;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _store = _interopRequireDefault(require("@/store"));
/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
function checkPermission(value) {
  // debugger;
  if (value && value instanceof Array && value.length > 0) {
    var perms = _store.default.getters && _store.default.getters.perms;
    var permissionperms = value;
    if (perms.includes('admin')) {
      return true;
    } // 如果是超管,都可以操作
    var hasPermission = perms.some(function (perm) {
      return permissionperms.includes(perm);
    });
    if (!hasPermission) {
      return false;
    }
    return true;
  } else {
    console.error("need perms! Like v-permission=\"['admin','editor']\"");
    return false;
  }
}