"use strict";

var _interopRequireDefault = require("/www/wwwroot/node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/www/wwwroot/node/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _request = _interopRequireDefault(require("@/utils/request"));
var _RestTable = _interopRequireDefault(require("@/components/RestTable.vue"));
var _adList = _interopRequireDefault(require("./adList.vue"));
var _adCateForm = _interopRequireDefault(require("./adCateForm.vue"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var _default = exports.default = {
  components: {
    RestTable: _RestTable.default,
    adList: _adList.default,
    adCateForm: _adCateForm.default
  },
  data: function data() {
    return {
      dialogFormVisible: false,
      //是否显示新增产品form
      dialogFormVisible1: false,
      listLoading: true,
      pk: 0
    };
  },
  methods: {
    // 根据id获取产品详情
    handleClick: function handleClick(item) {
      // console.log(item,'item')
      // 跳转到广告列表页
      this.$router.push({
        name: 'adList',
        query: {
          id: item.id,
          title: item.title
        }
      });
    },
    // 顶部添加按钮--显示新增产品form
    handleAdd: function handleAdd() {
      this.dialogFormVisible = true;
    },
    handleEdit: function handleEdit(id) {
      // console.log(id)
      this.pk = id;
      this.dialogFormVisible1 = true;
    },
    reload: function reload() {
      // 刷新页面
      window.location.reload();
    },
    editAdCate: function editAdCate(val) {
      var _this = this;
      // console.log(val, '修改类别')
      // 1.加载中
      this.listLoading = true;
      // 2.发送请求，添加产品
      (0, _request.default)({
        url: "/AdCate/".concat(this.pk, "/"),
        method: 'put',
        params: {},
        // url参数
        data: _objectSpread(_objectSpread({}, val), {}, {
          title: val.title,
          rate: val.rate,
          status: val.status
        }) // 请求体参数
      }).then(function (response) {
        if (response.code === 200 || 201) {
          // console.log('修改成功',response.data.id);

          // 3.提示添加成功
          _this.$message({
            message: "\u4FEE\u6539\u6210\u529F",
            type: 'success'
          });

          // 关闭loading
          _this.listLoading = false;
          // 4.关闭新增产品form
          _this.dialogFormVisible1 = false;
          _this.reload();
        } else {
          _this.$message({
            message: "\u4FEE\u6539\u5931\u8D25",
            type: 'error'
          });
        }
      }).catch(function (err) {
        console.log(err, '修改失败');
      });
    },
    // 添加广告类别
    addAdCate: function addAdCate(val) {
      var _this2 = this;
      // console.log(val, '新增类别')
      // 1.加载中
      this.listLoading = true;
      // 2.发送请求，添加产品
      (0, _request.default)({
        url: "/AdCate/",
        method: 'post',
        params: {},
        // url参数
        data: _objectSpread(_objectSpread({}, val), {}, {
          title: val.title,
          rate: val.rate || 1,
          status: val.status || 1
        }) // 请求体参数
      }).then(function (response) {
        if (response.code === 200 || 201) {
          // console.log('新增成功',response.data.id);

          // 3.提示添加成功
          _this2.$message({
            message: "\u6DFB\u52A0\u6210\u529F",
            type: 'success'
          });
          // 关闭loading
          _this2.listLoading = false;
          // 4.关闭新增产品form
          _this2.dialogFormVisible = false;
          _this2.reload();
        } else {
          _this2.$message({
            message: "\u6DFB\u52A0\u5931\u8D25",
            type: 'error'
          });
        }
      }).catch(function (err) {
        console.log(err, '新增失败');
      });
    },
    // 取消新增产品
    cancel: function cancel() {
      this.dialogFormVisible = false;
      this.$refs.restTable.reloadData();
    },
    cancel1: function cancel1() {
      this.dialogFormVisible1 = false;
      this.$refs.restTable.reloadData();
    }
  }
};