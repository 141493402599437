"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-row", {
    staticStyle: {
      "padding-top": "20px",
      "padding-left": "20px"
    }
  }, [_c("el-form", {
    staticStyle: {
      "padding-top": "20px",
      "padding-left": "20px",
      "margin-bottom": "5px"
    },
    attrs: {
      inline: true
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "店铺名称"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入店铺名称"
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search",
      size: "small"
    },
    on: {
      click: function click($event) {
        return _vm.getShopList(1);
      }
    }
  }, [_vm._v("搜索")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "default",
      icon: "el-icon-refresh-right",
      size: "small"
    },
    on: {
      click: _vm.resetShopList
    }
  }, [_vm._v("重置")])], 1)], 1), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-plus",
      size: "small"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v("添加标签")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "default",
      icon: "el-icon-refresh-right",
      size: "small"
    },
    on: {
      click: _vm.reload
    }
  }, [_vm._v("刷新")])], 1), _vm._v(" "), _c("el-row", {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    staticStyle: {
      padding: "0 20px"
    },
    attrs: {
      md: 24
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.is_shop_list,
      expression: "is_shop_list"
    }],
    attrs: {
      data: _vm.shop_list.results,
      border: "",
      stripe: "",
      "header-cell-style": {
        fontWeight: "700",
        backgroundColor: "#eaedf4"
      }
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "60"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "name",
      label: "店铺名称",
      width: "250"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "tag",
      label: "标签"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._l(scope.row.tag.split(","), function (item, index1) {
          return scope.row.tag && _vm.tag_list[item] ? _c("el-tag", {
            key: index1,
            staticStyle: {
              "margin-left": "5px"
            },
            attrs: {
              closable: ""
            },
            on: {
              close: function close($event) {
                return _vm.handleClose(scope.row.id, item);
              }
            }
          }, [_vm._v(_vm._s(_vm.tag_list[item]))]) : _vm._e();
        }), _vm._v(" "), _c("el-button", {
          staticClass: "button-new-tag",
          attrs: {
            size: "small"
          },
          on: {
            click: function click($event) {
              return _vm.showSelect(scope.row.id);
            }
          }
        }, [_vm._v("+ 添加标签")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "logo",
      label: "logo",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.url != "" ? _c("img", {
          staticStyle: {
            width: "100px",
            height: "100px"
          },
          attrs: {
            src: scope.row.url
          }
        }) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      fixed: "right",
      label: "操作",
      width: "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-upload", {
          staticClass: "upload-demo",
          attrs: {
            action: "https://jk.91kou.cn/api/system/report/report_shop_file_up/",
            headers: {
              Authorization: _vm.pic_header
            },
            data: {
              report_shop_id: scope.row.id
            },
            "on-success": _vm.reload
          }
        }, [_c("el-button", {
          attrs: {
            size: "small",
            type: "primary"
          }
        }, [_vm._v("上传logo")]), _vm._v(" "), _c("div", {
          staticClass: "el-upload__tip",
          attrs: {
            slot: "tip"
          },
          slot: "tip"
        }, [_vm._v("只能上传jpg/png文件，且不超过500kb")])], 1), _vm._v(" "), _c("el-button", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function click($event) {
              return _vm.deleteShop(scope);
            }
          }
        }, [_vm._v("删除商家")])];
      }
    }])
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.shop_list.count > 0,
      expression: "shop_list.count > 0"
    }],
    staticStyle: {
      "margin-top": "10px",
      "text-align": "center"
    },
    attrs: {
      total: _vm.shop_list.count,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.page_size
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.listQuery, "page_size", $event);
      },
      pagination: _vm.getShopList
    }
  }), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "添加标签",
      visible: _vm.dialogFormVisible,
      "before-close": _vm.cancel
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogFormVisible = $event;
      }
    }
  }, [_c("addTagForm", {
    ref: "addTag",
    attrs: {
      isEdit: false
    },
    on: {
      "add-tag": _vm.addTag,
      cancel: _vm.cancel
    }
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "添加标签",
      visible: _vm.dialogFormVisible2,
      "before-close": _vm.clearTag
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogFormVisible2 = $event;
      }
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, _vm._l(_vm.old_tag_list, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1), _vm._v(" "), _c("el-button", {
    staticClass: "button-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      click: _vm.addShopTag
    }
  }, [_vm._v("提交")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;