"use strict";

var _interopRequireDefault = require("/www/wwwroot/node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFileList = getFileList;
exports.upHeaders = upHeaders;
exports.upUrl = upUrl;
var _auth = require("@/utils/auth");
var _request = _interopRequireDefault(require("@/utils/request"));
function upUrl() {
  return process.env.VUE_APP_BASE_API + '/file/';
}
function upHeaders() {
  return {
    Authorization: "Bearer " + (0, _auth.getToken)()
  };
}
function getFileList(query) {
  return (0, _request.default)({
    url: '/file/',
    method: 'get',
    params: query
  });
}