"use strict";

var _typeof = require("/www/wwwroot/node/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var echarts = _interopRequireWildcard(require("echarts"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var _default2 = exports.default = {
  name: 'BarChart',
  props: {
    option: {
      type: Object,
      default: function _default() {}
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      window.addEventListener('resize', _this.resize);
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.resize);
  },
  methods: {
    initChart: function initChart() {
      var dom1 = this.$refs.chart1;
      if (!dom1) {
        console.error('DOM element not found or not rendered.');
        return;
      }
      this.chart = echarts.init(dom1);
      this.chart.setOption(this.option);
    },
    resize: function resize() {
      if (this.chart) {
        this.chart.resize();
      }
    }
  }
};