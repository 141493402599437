"use strict";

var _interopRequireDefault = require("/www/wwwroot/node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/www/wwwroot/node/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _request = _interopRequireDefault(require("@/utils/request"));
var _RestTable = _interopRequireDefault(require("@/components/RestTable.vue"));
var _goodForm = _interopRequireDefault(require("./goodForm.vue"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var _default = exports.default = {
  components: {
    RestTable: _RestTable.default,
    GoodForm: _goodForm.default
  },
  data: function data() {
    return {
      // 顶部搜索框form数据
      formInline: {
        keyword: '' //搜索关键词
      },
      dialogFormVisible: false,
      //是否显示新增产品form
      listLoading: true,
      is_day_nums: false
    };
  },
  methods: {
    // 根据id获取产品详情
    handleClick: function handleClick(item) {
      // 跳转到产品详情页面
      this.$router.push({
        name: 'goodDetail',
        query: {
          id: item.id
        }
      });
    },
    handleClick1: function handleClick1(item, type) {
      var _this = this;
      // 弹窗提示，如果用户确认则发请求
      this.$confirm("\u786E\u8BA4".concat(type, "\u5546\u54C1\u5417"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _request.default)({
          url: "/system/goods/update_goods_status/",
          method: 'put',
          // params: { shop: this.$user.data.shop },// url参数
          data: {
            shop_id: _this.$user.data.shop,
            goods_id: item.id,
            active: type
          }
        }).then(function (res) {
          if (res.code == 200) {
            _this.$message.success('操作成功');
            _this.reload();
          } else {
            _this.$message.error('操作失败');
          }
        });
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
    // 顶部搜索框——根据商品名搜索
    handleFilter: function handleFilter(e) {
      // console.log(e.target.value, '搜索关键词')
      this.listLoading = true;
      // this.formInline.keyword = e.target.value
      // 调用子组件的goSearch方法
      this.$refs.target.handleFilter(this.formInline.keyword);
    },
    resetFilter: function resetFilter() {
      this.listLoading = true;
      this.formInline.keyword = '';
      this.$refs.target.resetFilter(this.formInline.keyword);
    },
    // 顶部添加按钮--显示新增产品form
    handleAdd: function handleAdd() {
      this.dialogFormVisible = true;
      this.getCharge(); // 获取扣费方式
    },
    getCharge: function getCharge() {
      var _this2 = this;
      (0, _request.default)({
        url: "/system/shopConfig/getConfig/",
        method: 'get',
        params: {
          shop_id: this.$user.data.shop
        }
      }).then(function (res) {
        if (res.data) {
          _this2.is_day_nums = res.data.is_day_nums;
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 添加产品——done
    addGood: function addGood(val) {
      var _this3 = this;
      console.log(val, '新增产品');
      // 1.加载中
      this.listLoading = true;
      // 2.发送请求，添加产品
      (0, _request.default)({
        url: "/Goods/",
        method: 'post',
        params: {
          shop: this.$user.data.shop
        },
        // url参数
        data: _objectSpread(_objectSpread({}, val), {}, {
          active: '待审核',
          d_cost_money: val.d_cost_money ? val.d_cost_money : 0,
          goods_cate: val.goods_cate || 3,
          parameter: val.parameter || '无',
          parameter_value: val.parameter_value || '无',
          shop: this.$user.data.shop,
          // 以下为新增字段
          brand: val.brand || '自有品牌',
          model: val.model || '无',
          main_class: val.main_class || 'GENERAL',
          sub_class: val.sub_class || 'GENERAL_other'
        }) // 请求体参数
      }).then(function (response) {
        if (response.code == 200 || 201) {
          // 将图片上传到服务器
          if (val.good_img) {
            var formData = new FormData();
            formData.append('file', val.good_img);
            formData.append('goods_id', response.data.id);
            (0, _request.default)({
              url: "/system/goods/goods_file_up/",
              method: 'post',
              data: formData
            }).then(function (res) {
              if (res.code === 200) {
                alert('图片上传成功');
              } else {
                alert('图片上传失败');
              }
            }).catch(function (err) {
              alert(err, '图片上传失败');
            });

            // 3.提示添加成功
            _this3.$message({
              message: "\u6DFB\u52A0\u6210\u529F",
              type: 'success'
            });

            // 关闭loading
            _this3.listLoading = false;
            // 4.关闭新增产品form
            _this3.dialogFormVisible = false;
            // this.reload()
          } else {
            // 3.提示添加成功
            _this3.$message({
              message: "\u6DFB\u52A0\u6210\u529F",
              type: 'success'
            });

            // 关闭loading
            _this3.listLoading = false;
            // 4.关闭新增产品form
            _this3.dialogFormVisible = false;
            // this.reload()
          }
        }
      }).catch(function (err) {
        console.log(err, '新增失败');
      });
    },
    reload: function reload() {
      // 刷新页面
      window.location.reload();
    },
    // 取消新增产品
    cancel: function cancel() {
      this.dialogFormVisible = false;
    }
  }
};