"use strict";

var _interopRequireDefault = require("/www/wwwroot/node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.decrypt = decrypt;
exports.default = void 0;
exports.encrypt = encrypt;
require("core-js/modules/es6.regexp.to-string");
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _aes = _interopRequireDefault(require("crypto-js/aes"));
var _core = _interopRequireDefault(require("crypto-js/core"));
var publicKeyString = "AAAAAAAAAAAAAAAA";
var iv = _core.default.enc.Utf8.parse('BBBBBBBBBBBBBBBB');
function encrypt(plaintext) {
  var key = _core.default.enc.Utf8.parse(publicKeyString);
  var encrypted = _aes.default.encrypt(plaintext, key, {
    iv: iv,
    mode: _core.default.mode.CBC
  });
  return encrypted.toString();
}
function decrypt(encrypted) {
  var key = _core.default.enc.Utf8.parse(publicKeyString);
  var decrypted = _core.default.AES.decrypt(encrypted, key, {
    iv: iv,
    mode: _core.default.mode.CBC
  });
  return decrypted.toString(_core.default.enc.Utf8);
}

// create an axios instance
var service = _axios.default.create({
  baseURL: "https://report.99zuji.com/api/user",
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent
  var encryptedData = encrypt(JSON.stringify(config.data));
  console.log(encryptedData);
  // 修改请求的配置
  config.data = encryptedData; // 将加密后的数据添加到请求体中

  return config;
}, function (error) {
  // do something with request error
  // console.log(error) // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  if (res.code == 1) {
    return res;
  }
  (0, _elementUi.Message)({
    message: res.msg || '请求出错',
    type: 'error',
    duration: 3 * 1000
  });
  return Promise.reject(new Error(res.msg || '请求出错'));
}, function (error) {
  // console.log(error,response) // for debug
  (0, _elementUi.Message)({
    message: '服务器错误',
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = exports.default = service;